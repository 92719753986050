import React, { PureComponent } from 'react';
import Navbar from '../../components/Navbar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import WCMBC_Logo from '../../assets/wcmbc_logo.svg';
import './styles.scss';

class Home extends PureComponent {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="home-page">
                <Navbar selected="home"/>
                <div id="home-page-content">
                    <Header />
                    <div className="text-container">
                        <img src={WCMBC_Logo} alt="wcmbc logo" className='logo' />
                        <p>
                            We are a group of marine modeling hobbyists, based in and around Cape Town, South Africa.
                        </p>
                        <p>
                            The Club exists to enable those of similar interests in model boat constructing and/or sailing, 
                            to practice their hobby within an organization, 
                            allowing mutual benefit and the further enjoyment of their common interest.
                        </p>
                        <p>
                            The Club always welcomes newcomers, be they experienced modelers, or novices of any age. 
                            In fact, novices are especially welcome, since the hobby will through them continue to grow and develop.
                            Experienced members are always willing to share their knowledge and assist newcomers.
                        </p>
                        <p>
                            Club Divisions include 3 main disciplines:
                        </p>
                        <ul>
                            <li>Sailing</li>
                            <li>Scale models</li>
                            <li>Speedboats</li>
                        </ul>
                    </div>
                    <div className="text-container">
                        <h2>Club Rules</h2>
                        <h3>General Rules</h3> 
                        <div>The following rules are to be followed whenever putting a boat on the water:</div> 
                        <ol>
                            <li>
                                <strong>Frequency Board!</strong>
                                <div>
                                    If you use an older remote (transmitter) that uses the 27 mhz frequency range, 
                                    please make sure to use the frequency peg board. 
                                    Find the peg with the frequency you need and replace it with a peg with YOUR name on it.
                                </div>
                            </li>
                            <li>
                                <strong>Personal space!</strong>
                                <div>
                                    Please steer clear of other boats on the water. 
                                    We don't want any collisions.
                                </div>
                            </li>
                            <li>
                                <strong>Speed Limits!</strong>
                                <div>
                                    Any boat that "goes fast", whether it is a speed boat, 
                                    scale model, hovercraft or any boat with a motor capable 
                                    of getting some speed, needs to please obey the 
                                    following rules:
                                </div>
                                <ol type="a">
                                    <li>
                                        If you need to do high speed testing, announce your 
                                        intention before you put your boat in the water to 
                                        give other boats users a chance to either get there 
                                        boats off the water or to get out of your way.
                                    </li>
                                    <li>
                                        Please do so in the middle of the dam away from any 
                                        other boats. This helps keep noise levels low and 
                                        your boat safe from the walls and other boats if it 
                                        (or you) loses control.
                                    </li>
                                    <li>
                                        If your boat is very loud (&gt;75DbA), you will need to 
                                        make an effort to reduce the noise before you can race 
                                        or use the boat on the dam.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Common Sense!</strong>
                                <ol type="a">
                                    <li>
                                        Please use common sense when it comes to putting your boat 
                                        on the water.
                                    </li>
                                    <li>
                                        Keep in mind that other boats on the water are your fellow 
                                        club members' pride and joy. Treat others how you would 
                                        like to be treated.
                                    </li>
                                    <li>
                                        Be friendly, ask questions and just enjoy the hobby.
                                    </li>
                                    <li>
                                        No shouting will be tolerated so as not to disturb the 
                                        residents.
                                    </li>
                                    <li>
                                        No chasing the wildlife.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <h3>Race Rules</h3>
                        <div>
                            The following rules are to be followed for sailing regattas and 
                            speedboat races:
                        </div>
                        <ol>
                            <li>
                                All NON-race participants:
                                <ol type="a">
                                    <li>
                                        Are to stay clear of the racecourse for the duration 
                                        of the sailing regattas.
                                    </li>
                                    <li>
                                        Are to remove their boats completely from the water 
                                        during the speedboat races.
                                    </li>
                                    <li>
                                        Are highly encouraged to join the next race.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                All race participants need to give their details to the race 
                                master before the race starts. He'll need your name and boat 
                                number (or identifier if you don't have one).
                            </li>
                            <li>
                                Race participants need to have their boats on the water near 
                                the start line before the 10 second countdown for the race 
                                starts.
                            </li>
                            <li>
                                When you finish the race, stay off the course until the rest 
                                of the boats finish the race. This is to make it easier for 
                                the race master to see who is left in the race. 
                            </li>
                        </ol>
                        <div>
                            With thanks, WCMBC committee. (06/07/2024 - version 1.0.0)
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Home;