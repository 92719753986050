import React, { PureComponent } from 'react';
import './styles.scss';

const dayNames = [
    "S",
    "M",
    "T",
    "W",
    "T",
    "F",
    "S",
    "S"
];

// Antons scale days
// • 18 August Sunday
// • 15 September Sunday
// • 20 October Sunday
// • 17 November Sunday
// • 15 December Sunday

// Event format must be YYYY-MM-DD (no extra 0's!)

class CalendarComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            events: null
        }
    }

    componentDidMount() {
        fetch("data/events.json", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => response.json())
        .then((events) => this.setState({ events }));
    }

    getDayDivs() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        let today = date.getDate();
        let daysInMonth = new Date(year, month + 1, 0).getDate();
        let day = null;
        let classes = "";
        let sundayCount = 0;
        let dayDivs = [];
        for (let i = 1; i <= daysInMonth; i++) {
            day = new Date(year, month, i).getDay();
            classes = "";
            if (i === today) {
                classes += ' today';
            }
            if (day === 0 || day === 6) {
                classes += ' weekend';
                if (day === 0) {
                    sundayCount++;
                    if (sundayCount === 1 || sundayCount === 3) {
                        classes += ' racing';
                    }
                }
            }
            if (this.state.events[year + '-' + (month + 1) + '-' + i]) {
                classes += ' event';
            }
            dayDivs.push(
                <div key={i} className={'day' + classes}>
                    <div>{i}</div>
                </div>
            );
        }
        return dayDivs;
    }

    getDayNameDivs() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = null;
        let dayNameDivs = [];
        for (let i = 1; i <= 7; i++) {
            day = new Date(year, month, i).getDay();
            dayNameDivs.push(
                <div key={i} className={'day-name'}>
                    <div>{dayNames[day]}</div>
                </div>
            );
        }
        return dayNameDivs;
    }

    getEventDivs() {
        let date = new Date();
        let thisYear = date.getFullYear();
        let thisMonth = date.getMonth();
        let eventDivs = [];
        for (let i in this.state.events) {
            let eventDate = new Date(i);
            let eventMonth = eventDate.getMonth();
            let eventYear = eventDate.getFullYear();
            if (eventMonth === thisMonth && eventYear === thisYear) {
                eventDivs.push(
                    <div className='event-text'>
                        <div className='event block' />
                        <h3>{this.state.events[i].title}</h3>
                        <ul>
                            {this.state.events[i].lines.map((line, idx) => <li key={i + "_" + idx}>{line}</li>)}
                        </ul>
                    </div>
                );
            }
        }
        return eventDivs;
    }

    render() {
        if (!this.state.events) {
            return (
                <div>Loading...</div>
            );
        }
        return(
            <div className='calendar'>
                { this.getEventDivs() }
                { this.getDayNameDivs() }
                { this.getDayDivs() }
            </div>
        );
    }
}

export default CalendarComponent;